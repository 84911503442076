import axios from 'axios';

export const validateApiCredentials = async (): Promise<boolean> => {
  try {
    const [read_token_health, contact_form_token_health] = await Promise.all([
      axios.get(`${process.env.REACT_APP_API_URL}/api/health-check-read-token`, {
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_API_READ_TOKEN}`
        }
      }),
      axios.get(`${process.env.REACT_APP_API_URL}/api/health-check-contact-form-token`, {
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_API_CONTACT_FORM_TOKEN}`
        }
      })
    ]);

    return read_token_health.status === 200 && contact_form_token_health.status === 200;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      if (error.response?.config.url?.includes('read_token')) {
        console.error('API validation failed for READ token');
      } else if (error.response?.config.url?.includes('contact_token')) {
        console.error('API validation failed for CONTACT token');
      }
    } else {
      console.error('Error during API validation');
    }
    return true;
  }
};
